import { Loader2 } from "lucide-react"
import { useState } from "react"
import { cn, srcset } from "~/lib/utils"

interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  hideOnLoad?: boolean
}

export default function Img({ src, alt, className, hideOnLoad }: ImgProps) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)

  return (
    <div className="">
      {!isLoaded && !isError && !hideOnLoad && (
        <div className="rounded-md aspect-[3/4] flex justify-center items-center bg-accent w-full h-full">
          <Loader2 className="animate-spin icon text-muted-foreground" />
        </div>
      )}
      {isError && (
        <div className="rounded-md aspect-[3/4] flex justify-center items-center bg-accent text-xs text-muted-foreground">
          Failed to load image
        </div>
      )}
      {!isError && (
        <img
          className={cn(
            !isLoaded && "hidden",
            "rounded-md w-fit h-fit aspect-[3/4] object-cover relative",
            className
          )}
          onLoad={() => setIsLoaded(true)}
          onError={() => setIsError(true)}
          alt={alt}
          src={src}
          srcSet={srcset(src!)}
          sizes="(max-width: 1024px) 100vw, 50vw"
        />
      )}
    </div>
  )
}
